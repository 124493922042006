export const typeVoucherOptions = [
	{
		text: 'Fixed Amount',
		value: 'fixed',
	},
	{
		text: 'Percentage',
		value: 'percentage',
	},
	{
		text: 'Shipping',
		value: 'shipping',
	},
	{
		text: 'Free Item',
		value: 'free',
	},
]

export const statusOrder = {
	// PENDING: 'PENDING',
	UNPAID: 'Menunggu Pembayaran',
	UNCONFIRM: 'Menunggu Konfirmasi',
	ON_PROCESS: 'Sedang Di Proses',
	ON_DELIVERY: 'Sedang Dikirim',
	DELIVERED: 'Sudah Dikirim',
	FINISH: 'Selesai',
	// PROCESS: 'PROCESS',
	UNCONFIRM_CANCEL_ORDER: 'Menuggu Konfirmasi Batal Order',
	'VOID BY USER': 'Dibatalkan User',
	'VOID BY ADMIN': 'Dibatalkan Admin',
	'VOID BY SYSTEM': 'Dibatalkan System',
}

export const statusOrderVariant = {
	PENDING: 'info',
	UNPAID: 'danger',
	UNCONFIRM: 'secondary',
	ON_PROCESS: 'primary',
	ON_DELIVERY: 'primary',
	DELIVERED: 'info',
	FINISH: 'success',
	'VOID BY USER': 'danger',
	'VOID BY ADMIN': 'danger',
	'VOID BY SYSTEM': 'danger',
	PROCESS: 'primary',
	UNCONFIRM_CANCEL_ORDER: 'secondary',
}

export const statusVariant = status => {
	if (parseInt(status) === 0 || status === false) {
		return {
			label: 'Tidak Aktif',
			variant: 'danger',
		}
	}
	return {
		label: 'Aktif',
		variant: 'success',
	}
}
